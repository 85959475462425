<template>
  <div class="heart-word">
    <!-- <van-nav-bar title="心里话详情" left-arrow @click-left="onClickLeft" /> -->
    <div class="heart-word-body">
      <ul class="heart-word-list">
        <li>
          <div class="header">
            <div class="img">
              <img src alt />
            </div>
            <div class="user-info">
              <h3>{{detailData.realName}}</h3>
              <p>{{detailData.deptName}}</p>
            </div>
          </div>
          <div class="content">
            <p>{{detailData.sendMessage}}</p>
            <div class="img-list" v-if="detailData.picture">
              <div v-for="(itm,ind) in pic" :key="ind">
                <img :src="itm" alt />
              </div>
            </div>
          </div>
          <div class="like">
            <p>{{detailData.createTime}}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { toDetail } from '@/api/heartWord';
export default {
  data() {
    return {
      detailData: {}
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //详情数据
    getDetail() {
      toDetail({
        id: this.$route.params.id
      })
        .then(res => {
          if (res.success) {
            this.detailData = res.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  computed: {
    pic() {
      return (this.detailData.picture || '').split(',');
    }
  },
  created() {
    this.getDetail();
  }
};
</script>
<style scoped lang="scss">
// 修改vant样式：>>>针对css, /deep/针对scss和less
$px: 1rem/75;
.heart-word {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .heart-word-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .heart-word-list {
      background: #fff;
      li {
        padding: 20 * $px;
        font-size: 28 * $px;
        color: #555;
        width: 750 * $px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8 * $px;
        box-sizing: border-box;
        .header {
          display: flex;
          border-bottom: 2 * $px solid #f3f3f3;
          padding-bottom: 18 * $px;
          .img {
            width: 80 * $px;
            height: 80 * $px;
            background: url('./../../assets/img/avatar.png');
            background-size: cover;
            border-radius: 50%;
            margin-right: 18 * $px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .user-info {
            h3 {
              font-size: 32 * $px;
              color: #222;
              margin-bottom: 6 * $px;
            }
            p {
              width: 550 * $px;
              font-size: 24 * $px;
              color: #999;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .content {
          p {
            margin: 18 * $px 0;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .img-list {
            display: flex;
            justify-content: space-between;
            & > div {
              background: url('./../../assets/img/list-compre.png') center;
              width: 200 * $px;
              height: 200 * $px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .like {
          margin-top: 18 * $px;
          font-size: 22 * $px;
          p {
            color: #999;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
